<template>
  <div>
    <div
      v-if="!isComponent"
      class="mb-1"
    >
      <span
        class="link"
        @click="back"
      > Назад </span>
    </div>
    <div class="container card p-2">
      <h1>{{ title }}</h1>
      <b-overlay :show="loading">
        <div v-if="isShow">
          <p v-if="partiesInfo.provider">
            <b>Поставщик:</b> {{ partiesInfo.provider.name }}
          </p>
          <p>
            <b>Дата:</b>
            {{
              partiesInfo.date
                ? formatDateYmd(partiesInfo.date, "DD.MM.YYYY")
                : "-"
            }}
          </p>
          <p><b>Комментарий:</b> {{ partiesInfo.comment }}</p>
          <p><b>Курс валют:</b> {{ partiesInfo.dollar_rate }}</p>
        </div>
        <!-- Products -->
        <div class="d-flex justify-content-end">
          <b-overlay :show="loadingBtn">
            <b-button
              variant="success"
              class="btn-tour-skip text-right mb-2"
              @click="tableToExcel"
            >
              <feather-icon
                icon="DownloadIcon"
                size="16"
              /> Юклаб олиш
            </b-button>
          </b-overlay>
        </div>
        <table
          v-if="productsList && productsList.data.length"
          class="table table-bordered table-responsive table-white-space"
        >
          <thead>
            <tr>
              <th>№</th>
              <th>Номенклатура</th>
              <th>Количиство</th>
              <th>Остатки</th>
              <th>Расходы</th>
              <th v-if="currency_id == 1">
                Цена(USD)
              </th>
              <th v-else>
                Цена(UZS so'm)
              </th>
              <th>Сумма</th>
              <th v-if="isMobileParty">
                IMEI 1
              </th>
              <th v-if="isMobileParty">
                IMEI 2
              </th>
              <th>Штрих-код</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in productsList.data"
              :key="index"
            >
              <td>{{ orderOfList(index) }}</td>
              <td width="400">
                {{ product.category.name_uz }}
                <span v-if="product.serial_number">{{ product.serial_number }}</span>
              </td>
              <td>
                {{ product.quantity }}
              </td>
              <td>
                <b> {{ product.residual_quantity }}</b>
              </td>
              <td>
                <b
                  class="link"
                  @click="openSold(product)"
                >{{
                  product.quantity - product.residual_quantity
                }}</b>
              </td>
              <td>
                <span v-if="isHWorkerRoute || currency_id == 2">
                  {{ product.cost | formatPrice }}
                </span>
                <span v-else>{{ product.cost_dollar | formatPrice }}</span>
              </td>

              <td width="15%">
                <span v-if="isHWorkerRoute || currency_id == 2">
                  {{ (product.quantity * product.cost) | formatPrice }} so'm
                </span>
                <span v-else>
                  {{ (product.quantity * product.cost_dollar) | formatPrice }}
                  $
                </span>
                <br>
                <span v-if="partiesInfo.dollar_rate && currency_id == 2">
                  {{
                    (product.cost / partiesInfo.dollar_rate).toFixed(2) *
                      product.quantity
                  }}
                  USD
                </span>
                <span v-else-if="partiesInfo.dollar_rate && product.cost_dollar">
                  {{
                    (product.quantity * partiesInfo.dollar_rate * product.cost_dollar)
                      | formatPrice
                  }}
                  UZS
                </span>
              </td>
              <td v-if="isMobileParty">
                {{ product.imei_1 }}
              </td>
              <td v-if="isMobileParty">
                {{ product.imei_2 }}
              </td>
              <td v-if="!isHWorker && isShow">
                <div id="qr">
                  <barcode
                    :width="1.7"
                    height="53"
                    font-size="11"
                    :value="product.barcode"
                  >
                    Show this if the rendering fails.
                  </barcode>
                </div>
              </td>
              <td>
                <span
                  v-if="!isHWorker"
                  class="text-center"
                >
                  <b-button
                    variant="outline-primary"
                    class="link"
                    @click="showPrint(product.barcode, product.name)"
                  >
                    <feather-icon
                      icon="PrinterIcon"
                      size="16"
                    />
                    Распечатать штрих-код
                  </b-button>
                </span>
                <br>
                <b-button
                  v-if="product.quantity != 0"
                  v-can="'warehouses.parties-reject-product'"
                  class="mt-1"
                  variant="outline-danger"
                  @click="openRejectModal(product)"
                >
                  <feather-icon icon="RepeatIcon" />
                  Возврат товар
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <b-overlay
          v-else
          :show="true"
        >
          <div style="height: 100px" />
        </b-overlay>
        <!--  -->
        <div v-if="productsList && productsList.data.length">
          <b-pagination
            :value="1"
            :total-rows="productsList.total"
            :per-page="filter.per_page"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="onPageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <b-button
          v-if="isShow && !isHWorker"
          class="mt-1 float-left"
          variant="primary"
          @click="makeNakladnoy"
        >
          <feather-icon
            icon="ClipboardIcon"
            size="16"
          />
          Накладной
        </b-button>
      </b-overlay>
    </div>
    <!-- table for excel -->
    <table
    v-show="false"
      v-if="productsList && productsList.data.length"
      ref="transferExcelTable"
      class="table table-bordered table-responsive table-white-space"
    >
      <thead>
        <tr>
          <th>№</th>
          <th>Номенклатура</th>
          <th>Количиство</th>
          <th>Остатки</th>
          <th>Расходы</th>
          <th v-if="currency_id == 1">
            Цена(USD)
          </th>
          <th v-else>
            Цена(UZS so'm)
          </th>
          <th>Сумма</th>
          <th v-if="isMobileParty">
            IMEI 1
          </th>
          <th v-if="isMobileParty">
            IMEI 2
          </th>
          <th>Штрих-код</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in productsList.data"
          :key="index"
        >
          <td>{{ orderOfList(index) }}</td>
          <td width="400">
            {{ product.category.name_uz }}
            <span v-if="product.serial_number">{{ product.serial_number }}</span>
          </td>
          <td>
            {{ product.quantity }}
          </td>
          <td>
            <b> {{ product.residual_quantity }}</b>
          </td>
          <td>
            <b
              class="link"
              @click="openSold(product)"
            >{{
              product.quantity - product.residual_quantity
            }}</b>
          </td>
          <td>
            <span v-if="isHWorkerRoute || currency_id == 2">
              {{ product.cost | formatPrice }}
            </span>
            <span v-else>{{ product.cost_dollar | formatPrice }}</span>
          </td>

          <td width="15%">
            <span v-if="isHWorkerRoute || currency_id == 2">
              {{ (product.quantity * product.cost) | formatPrice }} so'm
            </span>
            <span v-else>
              {{ (product.quantity * product.cost_dollar) | formatPrice }}
              $
            </span>
            <span v-if="partiesInfo.dollar_rate && currency_id == 2">
              {{
                (product.cost / partiesInfo.dollar_rate).toFixed(2) *
                  product.quantity
              }}
              USD
            </span>
            <span v-else-if="partiesInfo.dollar_rate && product.cost_dollar">
              ({{
                (product.quantity * partiesInfo.dollar_rate * product.cost_dollar)
                  | formatPrice
              }}
              UZS)
            </span>
          </td>
          <td v-if="isMobileParty">
            {{ product.imei_1 }}
          </td>
          <td v-if="isMobileParty">
            {{ product.imei_2 }}
          </td>
          <td v-if="!isHWorker && isShow">
            {{ product.barcode }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Rejected Products -->
    <div
      v-if="rejectedProducts && rejectedProducts.length"
      class="container card p-2"
    >
      <h1 class="text-warning">
        Возвращенный товары
      </h1>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>№</th>
            <th>Номенклатура</th>
            <th>Количиство</th>
            <th>Комментарий</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(product, index) in rejectedProducts"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td width="400">
              <span v-if="product.product && product.product.category">
                {{ product.product.category.name_uz }}
              </span>
            </td>
            <td>
              {{ product.rejected_quantity }}
            </td>
            <td>
              {{ product.comment }}
            </td>
            <td>
              {{ formatDateYmd(product.created_at) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modals -->
    <div v-if="isShow">
      <b-modal
        v-model="show_print_barcode"
        size="sm"
        centered
        hide-footer
        no-close-on-backdrop
        @close="show_print_barcode = false"
        @cancel="show_print_barcode = false"
      >
        <div class="text-center">
          <Print
            :barcode="product_barcode"
            :name="product_name"
          />
        </div>
      </b-modal>
    </div>

    <div v-if="reject_modal">
      <b-modal
        v-model="reject_modal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        @close="reject_modal = false"
        @cancel="reject_modal = false"
      >
        <RejectProductReason
          :is-hired="isHWorkerRoute"
          :product="reject_product"
          @submit="reject"
        />
      </b-modal>
    </div>
    <div v-if="product_create_modal">
      <b-modal
        v-model="product_create_modal"
        size="xl"
        centered
        hide-footer
        no-close-on-backdrop
        @close="product_create_modal = false"
        @cancel="product_create_modal = false"
      >
        <ProductCategoryCreate />
      </b-modal>
    </div>
    <!-- open sold products -->
    <b-modal
      id="modal-lg"
      v-model="showSoldModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      title="Расходы"
      @close="showSoldModal = false"
    >
      <div>
        <div class="d-flex">
          <div
            v-if="
              soldContracts && soldContracts.data && soldContracts.data.length
            "
          >
            <h4 class="mb-1 text-center">
              По контракту
            </h4>
            <div
              v-if="
                soldContracts && soldContracts.data && soldContracts.data.length
              "
            >
              <ProductContractsTable
                is-custom
                :contracts="soldContracts"
              />
            </div>
            <div v-else>
              Нет расходы по контракту
            </div>
          </div>
          <div v-if="soldCashs && soldCashs.data && soldCashs.data.length">
            <h4 class="mb-1 text-center">
              Наличными
            </h4>
            <div v-if="soldCashs && soldCashs.data && soldCashs.data.length">
              <BuyedForCashTable :contracts="soldCashs" />
            </div>
            <div v-else>
              Нет расходы наличными
            </div>
          </div>
          <div
            v-else-if="
              !(
                soldContracts &&
                soldContracts.data &&
                soldContracts.data.length
              )
            "
          >
            <h3 class="text-center">
              Нет расходы
            </h3>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import Print from '@/views/print/print.vue'
import RejectProductReason from '@/components/modals/rejectProductReason.vue'
import ProductCategoryCreate from '@/views/settings/product-category/index.vue'
import VueBarcode from 'vue-barcode'
import ProductContractsTable from '@/components/tables/productContracts.vue'
import BuyedForCashTable from '@/components/tables/buyedForCash.vue'

export default {
  name: 'Create',
  components: {
    barcode: VueBarcode,
    Print,
    RejectProductReason,
    ProductCategoryCreate,
    ProductContractsTable,
    BuyedForCashTable,
  },
  props: {
    pPartyId: {
      type: Number,
      default: () => null,
    },
    isComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loadingBtn: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      reject_modal: false,
      rejectedProducts: [],
      isMobileParty: false,
      currencies: [
        { id: 1, name: 'USD(dollar)' },
        { id: 2, name: "UZS(so'm)" },
      ],
      soldContracts: null,
      soldCashs: null,
      showSoldModal: false,
      currency_id: 1,
      reject_product: {},
      productsList: null,
      page: 1,
      filter: {
        per_page: 50,
      },
      product_barcode: '',
      product_name: '',
      show_print_barcode: false,
      product_create_modal: false,
      title: '',
      visible: false,
      loading: false,
      required,
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) { return window.btoa(unescape(encodeURIComponent(s))) },
      format(s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]) },
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      partiesInfo: 'parties/GET_ITEM',
      product_categories: 'productCategory/GET_ITEMS',
    }),
    isHWorker() {
      return !!this.partiesInfo.hired_worker_user_id
    },
    itemId() {
      if (this.pPartyId) {
        return this.pPartyId
      }
      return this.$route.params.id
    },
    isShow() {
      return true
    },
    isHWorkerRoute() {
      return !!(this.$route.name == 'warehouseParty-show-hworker')
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getProductsAction()
    },
  },
  mounted() {
    this.showAction()
    this.title = this.$t('Поступление товаров')
  },
  methods: {
    async save() {
      console.log('hi')
    },
    onPageChange(page) {
      this.page = page
    },
    orderOfList(index) {
      return (this.page - 1) * this.filter.per_page + index + 1
    },
    openRejectModal(product) {
      this.reject_modal = true
      this.reject_product = product
    },
    showPrint(barcode, name) {
      this.product_barcode = barcode
      this.product_name = name
      this.show_print_barcode = true
    },

    reject(data) {
      if (this.isHWorkerRoute) {
        this.rejectProductHiredWorker(data).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно сохранено'))
            this.reject_modal = false
            this.showAction()
          }
        })
      } else {
        this.rejectProduct(data).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно сохранено'))
            this.reject_modal = false
            this.showAction()
          }
        })
      }
    },
    async openSold(product) {
      await this.getContractProducts({
        barcode: product.barcode,
        relations: 'contract.client|payment',
        per_page: 999,
        is_rejected: 0,
      }).then(res => {
        this.soldContracts = {
          data: res.data.data.filter(el => el.contract_id > 0),
          total: res.data.total,
        }
        this.soldCashs = {
          data: res.data.data.filter(el => el.contract_id < 0),
          total: res.data.total,
        }
      })
      this.showSoldModal = true
    },
    showAction() {
      let showData = null
      this.loading = true
      if (this.isHWorkerRoute) {
        showData = {
          id: this.itemId,
          relations:
            'provider|products.category|rejectedProducts.createdUser|rejectedProducts.product.category',
        }
      } else {
        showData = {
          id: this.itemId,
          relations:
            'provider|rejectedProducts.createdUser|rejectedProducts.product.category',
        }
      }
      this.showItem(showData)
        .then(res => {
          this.rejectedProducts = res.data.rejected_products

          this.getProductsAction()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getProductsAction() {
      this.getVisiblePartyProducts({
        party_id: this.partiesInfo.id,
        appends: 'residual_quantity',
        per_page: this.filter.per_page,
        page: this.page,
        relations: 'category|party.provider|party.createdUser',
      }).then(res => {
        this.productsList = res.data
        this.isMobileParty = res.data.data.some(el => el.imei_1)
      })
    },
    makeNakladnoy() {
      this.makeNakladnoyPdf(this.$route.params.id).then(res => {
        window.open(
          `${`${this.storageUrl}/nakladnoys/${res.data}`}.pdf`,
          '_blank',
        )
      })
    },

    async tableToExcel(table) {
      const tableeee = this.$refs.transferExcelTable
      this.loadingBtn = true
      // await this.getItemsAction({ ...this.filterModel, page: this.page, per_page:9999}).then(res => {
      //   this.itemsX = res.data
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: 'Поступление товаров', table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `Поступление товаров(${this.partiesInfo.provider.name}).xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
      // })
      this.loadingBtn = false
    },
    ...mapActions({
      getVisiblePartyProducts: 'warehouse/getVisiblePartyProducts',
      getCategories: 'productCategory/index',
      getContractProducts: 'warehouse/getContractProducts',
      showItem: 'parties/show',
      makeNakladnoyPdf: 'resource/makeNakladnoyPdf',
      rejectProduct: 'warehouse/rejectProduct',
      rejectProductHiredWorker: 'warehouse/rejectProductHiredWorker',
    }),
  },
}
</script>
